<template>
  <link href='https://fonts.googleapis.com/css?family=Comfortaa' rel='stylesheet'>
  <h1>Hibernia on your other devices!</h1>
  <img alt="Hibernia Logo" src="./assets/hibernialogo.png">
  <p>Grab your subscription ID from the app (tap the HiberniaVPN text) and enter below!</p>
  <DownloadForm/>
</template>

<script>
import DownloadForm from './components/DownloadForm.vue'

export default {
  name: 'App',
  components: {
    DownloadForm
  }
}
</script>

<style>
#app {
  font-family: 'Comfortaa';
  font-weight: 800;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
img {
  border-radius: 50%;
  max-width: 20vw;
  padding: 30px;
}
</style>
