<template>
  <div class="container">
    <label>Subscription ID:</label>
    <input v-model="subscriptionID">

    <label>Location:</label>
    <select v-model="selectedLocation">
      <option disabled value="">Please select</option>
      <option v-for="location in Object.keys(locations)" :key="location">{{ location }}</option>
    </select>

    <br>
    <br>

    <button @click="downloadConfiguration" :disabled="isButtonDisabled">Download your configuration</button>
    <br>
    <p v-if="error_message != ''">{{ error_message }}</p>
  </div>
</template>

<script>
  import axios from 'axios';
  import { getToken } from 'firebase/app-check';

  export default {
    name: "DownloadForm",
    inject: ['appCheck'],
    data() {
      return {
        subscriptionID: '',
        selectedLocation: '',
        locations: {"London": "lon", "New York": "nyc", "Singapore": "sgy"},
        recaptchaSiteKey: 'sitekey',
        recaptchaToken: '',
        error_message: ''
      };
    },
    computed: {
      isButtonDisabled() {
        const regex = new RegExp("^\\d{15}$")

        if (this.subscriptionID == '' || !regex.test(this.subscriptionID) || this.selectedLocation == '') {
          return true
        } else {
          return false
        }
      }
  },
  methods: {
    async downloadConfiguration() { 
      try {
        const appCheckToken = await getToken(this.appCheck, false);
        const endpoint = `https://provision-configuration-1-xgpoqrynja-lm.a.run.app?app_token=${appCheckToken.token}&subscription_id=${this.subscriptionID}&location=${this.locations[this.selectedLocation]}`;

        const response = await axios.get(endpoint)
        console.log(response)

        if (response.status == 200) {
          const blob = new Blob([response.data.configuration], {type: 'application/octet-stream'});
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = 'hiberniaconfig.ovpn';
          link.click();
        }
      } catch (error) {
        this.error_message = error;
        console.error(error);
      }
    },
    onRecaptchaVerified(token) {
      this.recaptchaToken = token;
    },
  },
};

</script>

<style scoped>
  /* Desktop styles */
  button:disabled {
    filter: brightness(30%);
  }
  @media (min-width: 768px) {
    label {
      display: inline-block;
      width: 150px;
      margin-right: 10px;
      text-align: right;
    }

    input[type="text"], select {
      width: 250px;
      padding: 5px;
      font-size: 16px;
      border-radius: 5px;
      border: 1px solid #ccc;
      margin-bottom: 10px;
    }

    button {
      font-family: 'Comfortaa';
      background-color: #11CCF4;
      color: white;
      border: none;
      border-radius: 5px;
      padding: 10px 20px;
      font-size: 16px;
      cursor: pointer;
    }
  }

  /* Mobile styles */
  @media (max-width: 767px) {
    label {
      display: block;
      margin-bottom: 5px;
    }

    input[type="text"], select {
      width: 100%;
      padding: 5px;
      font-size: 16px;
      border-radius: 5px;
      border: 1px solid #ccc;
      margin-bottom: 10px;
    }

    button {
      background-color: #11CCF4;
      color: white;
      border: none;
      border-radius: 5px;
      padding: 10px 20px;
      font-size: 16px;
      width: 100%;
      cursor: pointer;
    }
  }
</style>