import { createApp } from 'vue'
import App from './App.vue'

import { initializeApp } from "firebase/app";
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';

//const { initializeApp } = require("firebase/app");
//const { initializeAppCheck, ReCaptchaV3Provider } = require("firebase/app-check");

const firebaseConfig = {
  apiKey: "AIzaSyD68aMlnKJHA2rc-IZCknQ1CcZ12-mv8QI",
  authDomain: "hiberniavpn.firebaseapp.com",
  databaseURL: "https://hiberniavpn-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "hiberniavpn",
  storageBucket: "hiberniavpn.appspot.com",
  messagingSenderId: "952028094521",
  appId: "1:952028094521:web:a67b796edcec7212652de8"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
  
// Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
// key is the counterpart to the secret key you set in the Firebase console.
const appCheck = initializeAppCheck(firebaseApp, {
  provider: new ReCaptchaV3Provider('6Ld65qokAAAAAKY_B8x0ISbo34p40alJxlusU43N'),

  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  isTokenAutoRefreshEnabled: true
});

const app = createApp(App)

app.provide('firebase', firebaseApp);
app.provide('appCheck', appCheck);

app.mount('#app');
